export const ImageFormatsIcon = () => {
    return (
        <svg width="37" height="30" viewBox="0 0 37 30" fill="none" xmlns="http://www.w3.org/2000/svg" className="fill-black dark:fill-white">
            <path
                d="M3.38831 1.43894H29.3557C30.4449 1.43894 31.3025 2.29346 31.3025 3.38393V22.5086C31.3025 23.5982 30.4449 24.4532 29.3557 24.4532H3.38831C2.29827 24.4532 1.44331 23.5982 1.44331 22.5086V3.38393C1.44331 2.29346 2.29827 1.43894 3.38831 1.43894ZM3.38831 0C1.52518 0 0 1.5208 0 3.38393V22.5086C0 24.3717 1.52518 25.8943 3.38831 25.8943H29.3557C31.218 25.8943 32.7392 24.3713 32.7392 22.5086V3.38393C32.7392 1.5208 31.218 0 29.3557 0H3.38831Z"
            />
            <path
                d="M11.0329 6.34568C11.781 7.4239 11.5144 8.88297 10.4336 9.63111C9.35536 10.3766 7.89848 10.1122 7.15296 9.03356C6.40482 7.95315 6.66748 6.49671 7.74788 5.74813C8.8261 5.00042 10.2874 5.26702 11.0329 6.34568ZM12.2162 5.52662C11.0259 3.80751 8.64574 3.37675 6.92883 4.56485C5.21016 5.75513 4.77677 8.13526 5.96705 9.85437C7.15734 11.5713 9.53484 12.0047 11.2544 10.8144C12.9739 9.62411 13.4064 7.24616 12.2162 5.52662Z"
            />
            <path
                d="M24.6087 6.24782C24.4214 6.26095 24.2463 6.34588 24.1206 6.48727L14.0765 17.315L8.28312 13.1111C8.01215 12.9124 7.63829 12.9286 7.38526 13.1496L0.446676 19.2232C0.127545 19.4789 0.0842059 19.9508 0.355183 20.2585C0.624409 20.5663 1.09632 20.589 1.39313 20.304L7.90227 14.6109L13.7482 18.8559C14.0424 19.0709 14.4508 19.0293 14.6973 18.7622L25.1783 7.46306C25.6322 6.98852 25.2654 6.20448 24.6087 6.24782Z"
            />
            <path
                d="M14.1299 17.5476C13.9426 17.5616 13.7692 17.6457 13.6445 17.7845L10.9014 20.742C10.6116 21.0318 10.6208 21.5015 10.922 21.7795C11.2232 22.0575 11.6925 22.0277 11.9591 21.7178L14.6977 18.7624C15.1508 18.2888 14.784 17.5043 14.1299 17.5476Z"
            />
            <path
                d="M24.5994 6.24813C23.9883 6.28447 23.6989 7.0186 24.1205 7.46337L31.2092 15.1063C31.8606 15.8107 32.9165 14.8301 32.2643 14.1279L25.1786 6.48759C25.0332 6.32343 24.8183 6.23631 24.5994 6.24813Z"
            />
            <path
                d="M32.7393 3.69336C31.7793 3.69336 31.7793 5.13624 32.7393 5.13624C33.8294 5.13624 34.6821 5.99119 34.6821 7.08079V26.2033C34.6821 27.2955 33.8298 28.1509 32.7393 28.1509H6.76756C5.67753 28.1509 4.82257 27.2955 4.82257 26.2033C4.84315 25.7953 4.51482 25.4512 4.1042 25.4512C3.69357 25.4512 3.36525 25.7953 3.38407 26.2033C3.38407 28.0664 4.90443 29.5872 6.76756 29.5872H32.7393C34.6025 29.5872 36.1228 28.0664 36.1228 26.2033V7.08123C36.1228 5.21897 34.6025 3.69336 32.7393 3.69336Z"
            />
        </svg>
    );
};
