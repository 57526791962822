import { useKeyboard } from "client/hooks/utils/useKeyboard";
import { useOnOutsideClick } from "client/hooks/utils/useOnOutsideClick";
import { useRef } from "react";

export const usePopup = (isOpen: boolean, close?: () => void) => {
    const parentRef = useRef<HTMLDivElement | null>(null);
    useOnOutsideClick(parentRef, isOpen, () => close && close());
    useKeyboard("Escape", isOpen, () => close && close());

    return { parentRef };
};
