export const HeartIcon = () => {
    return (
        <svg
            width="38"
            height="30"
            viewBox="0 0 40 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-black dark:fill-white"
        >
            <path d="M20.0034 33.9994C19.7205 33.9994 19.4486 33.8872 19.2469 33.6871L18.9997 33.4399C15.2928 29.7361 12.4885 27.1282 10.0137 24.8263C9.26926 24.1338 8.55051 23.4643 7.84014 22.7969C5.1731 20.2865 2.6868 17.7907 1.55995 14.8602C0.204692 11.3293 0.59131 7.61187 2.62079 4.65933C4.62566 1.74135 7.93863 0 11.4826 0C14.3995 0 17.2793 1.163 19.8101 3.36431L19.9992 3.54976L20.1396 3.41041C22.7207 1.163 25.6 0 28.5169 0C32.0604 0 35.3733 1.74135 37.3787 4.65933C39.4082 7.61187 39.7948 11.3303 38.4401 14.8591C37.3138 17.7876 34.8274 20.2844 32.1609 22.7958C31.4506 23.4632 30.7329 24.1306 29.9911 24.8211C27.5152 27.124 24.7083 29.734 20.9998 33.4399C20.9249 33.5153 20.8626 33.5771 20.795 33.6505C20.598 33.8663 20.3209 33.992 20.0291 33.9994C20.0207 33.9994 20.0118 33.9994 20.0034 33.9994ZM11.4826 2.14578C8.64533 2.14578 5.99349 3.53929 4.38886 5.87471C2.76591 8.23633 2.46468 11.2308 3.56272 14.0901C4.52769 16.5984 6.83168 18.9003 9.31013 21.2336C10.0174 21.8989 10.7335 22.5653 11.4753 23.2547C13.8469 25.4613 16.5202 27.9476 19.9998 31.4072C23.4877 27.9392 26.1594 25.455 28.5294 23.2505C29.2692 22.5622 29.9837 21.8968 30.691 21.2336C33.1678 18.8992 35.4729 16.5963 36.4368 14.089C37.5348 11.2308 37.2331 8.23633 35.6101 5.87471C34.005 3.53929 31.3542 2.14578 28.5169 2.14578C26.1249 2.14578 23.7329 3.12752 21.5976 4.98413L20.7573 5.81708C20.3403 6.22675 19.6708 6.2299 19.2527 5.82023L18.3553 4.94117C16.2672 3.12752 13.8746 2.14578 11.4826 2.14578Z" />
        </svg>
    );
};
