import { modalPortalContainerId } from "client/config/divIds";
import { MutableRefObject, useCallback, useEffect, useState } from "react";

export const useOnOutsideClick = <T extends HTMLElement>(
    ref: MutableRefObject<T | null>,
    shouldSetListener: boolean,
    callback: () => void,
    ignoreElementsWithParentIds?: string[],
    extraOptions?: {
        /*
         * delay the timer setting time in ms
         */
        delay?: number;
    },
) => {
    const [isSet, setIsSet] = useState(false);

    const handleClick = useCallback(
        (event: MouseEvent) => {
            if (!ref.current || ref.current.contains(event.target as Node)) return;
            if (
                ignoreElementsWithParentIds &&
                ignoreElementsWithParentIds.some((id) => {
                    const parent = document.getElementById(id);
                    if (!parent) return false;
                    if (parent.contains(event.target as Node)) return true;
                    return false;
                })
            )
                return;
            callback();
        },
        [callback, ignoreElementsWithParentIds, ref],
    );

    const handleTouchStart = useCallback(
        (event: TouchEvent) => {
            if (!ref.current || ref.current.contains(event.target as Node)) {
                return;
            }

            // check if the element is within the modal
            if (document.getElementById(modalPortalContainerId)?.contains(event.target as Node)) {
                return;
            }

            const shouldIgnore = !!ignoreElementsWithParentIds?.some((id) => {
                const parent = document.getElementById(id);
                if (!parent) return false;
                if (parent.contains(event.target as Node)) return true;
                return false;
            });

            if (shouldIgnore) {
                return;
            }
        },
        [callback, ignoreElementsWithParentIds, ref],
    );

    const setListener = useCallback(() => {
        if (shouldSetListener && !isSet) {
            setTimeout(() => {
                document.addEventListener("click", handleClick);
                document.addEventListener("touchstart", handleTouchStart);
                setIsSet(true);
            }, extraOptions?.delay || 0);
        }
    }, [handleClick, handleTouchStart, isSet, shouldSetListener]);

    useEffect(() => {
        setListener();
    }, [ref, shouldSetListener, callback, setListener]);
};
