import type { ReactNode } from "react";

interface TutorialParentProps {
    children: ReactNode;
}

export const TutorialParent = ({ children }: TutorialParentProps) => {
    return (
        <div className=" sm:md:overflow-x-hidden flex flex-col lg:flex-row-reverse items-center justify-center gap-12 mt-20 mb-20 md:mb-36 text-black dark:text-white">
            {children}
        </div>
    );
};
