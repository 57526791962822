export const PrivacyGuaranteedIcon = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" className="fill-black dark:fill-white">
            <path
                d="M33.2245 5.02563L20.2246 2.02565C20.0768 1.99145 19.9232 1.99145 19.7754 2.02565L6.77551 5.02563C6.55509 5.07643 6.3584 5.20047 6.21757 5.37748C6.07674 5.55449 6.00008 5.77402 6.00011 6.00022V20.6593C5.98572 24.6817 7.34258 28.589 9.84695 31.7367C12.3513 34.8844 15.8537 37.0846 19.7764 37.9746C19.9242 38.0085 20.0778 38.0085 20.2256 37.9746C24.148 37.0843 27.6499 34.8839 30.1539 31.7362C32.6579 28.5885 34.0145 24.6815 33.9999 20.6593V6.00022C33.9999 5.77402 33.9233 5.55449 33.7824 5.37748C33.6416 5.20047 33.4449 5.07643 33.2245 5.02563ZM31.9999 20.6593C32.013 24.1893 30.8359 27.6206 28.6585 30.3991C26.4811 33.1776 23.4307 35.141 20 35.9722C16.5693 35.141 13.5189 33.1776 11.3415 30.3991C9.16414 27.6206 7.98696 24.1893 8.0001 20.6593V6.79562L20 4.02663L31.9999 6.79562V20.6593Z"
            />
            <path
                d="M15.5804 17.9676C15.5034 17.8886 15.4115 17.8257 15.3099 17.7825C15.2084 17.7393 15.0993 17.7167 14.989 17.716C14.8787 17.7153 14.7693 17.7365 14.6673 17.7784C14.5652 17.8203 14.4725 17.8821 14.3945 17.9601C14.3165 18.0381 14.2547 18.1308 14.2128 18.2329C14.1709 18.3349 14.1497 18.4443 14.1504 18.5546C14.1511 18.6649 14.1737 18.774 14.2169 18.8755C14.2601 18.9771 14.323 19.069 14.402 19.146L17.938 22.6816C18.0943 22.8379 18.3062 22.9256 18.5272 22.9256C18.7482 22.9256 18.9601 22.8379 19.1164 22.6816L25.5983 16.2005C25.6773 16.1235 25.7402 16.0316 25.7834 15.93C25.8266 15.8285 25.8492 15.7194 25.8499 15.6091C25.8506 15.4988 25.8294 15.3894 25.7875 15.2874C25.7456 15.1853 25.6838 15.0926 25.6058 15.0146C25.5278 14.9366 25.4351 14.8748 25.333 14.8329C25.231 14.791 25.1216 14.7698 25.0113 14.7705C24.901 14.7712 24.7919 14.7938 24.6904 14.837C24.5889 14.8802 24.4969 14.9431 24.4199 15.0221L18.5272 20.9144L15.5804 17.9676Z"
            />
        </svg>
    );
};
