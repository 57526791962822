import { IRLogo } from "components/common/Icons/IRLogo";
import { DesktopNavigation } from "../DesktopNavigation/DesktopNavigation";
import { MobileNavigation } from "../MobileNavigation/MobileNavigation";
import { Internationalization } from "components/modules/Internationalization/Internationalization";
import type { LanguageProps } from "components/modules/Layout/Footer/Footer";
import { Theme } from "./Theme/Theme";

interface NavbarProps extends LanguageProps {}

export const Navbar = ({ url, urlSlugVariables }: NavbarProps) => {
    return (
        <div className="flex flex-row items-center h-16 px-4 py-4 border-b border-b-slate-200 dark:border-b-darkSurface-600 md:px-8">
            <IRLogo isEditor={false} />
            <DesktopNavigation isEditor={false} />
            <div className="ml-auto lg:hidden">
                <MobileNavigation url={url} urlSlugVariables={urlSlugVariables} isEditor={false} />
            </div>
            <div className="items-center hidden gap-6 pl-4 lg:flex">
                <Theme />
                <Internationalization url={url} urlSlugVariables={urlSlugVariables} onlyIconMode={true} />
            </div>
        </div>
    );
};
