import { useTheme } from "client/contexts/ThemeProvider";
import { useToggle } from "client/hooks/utils/useToggle";
import { translateFunc } from "client/utils/translateFunc";
import { MonitorIcon } from "components/common/Icons/theme/MonitorIcon";
import type { MoonIconProps } from "components/common/Icons/theme/MoonIcon";
import type { SunIconProps } from "components/common/Icons/theme/SunIcon";
import { Popup } from "components/common/Popup/Popup";
import dynamic from "next/dynamic";

const MoonIcon = dynamic<MoonIconProps>(
    () => import("components/common/Icons/theme/MoonIcon").then((mod) => mod.MoonIcon),
    {
        ssr: false,
    },
);
const SunIcon = dynamic<SunIconProps>(
    () => import("components/common/Icons/theme/SunIcon").then((mod) => mod.SunIcon),
    {
        ssr: false,
    },
);

export const Theme = () => {
    const { theme, appliedTheme, setTheme: _setTheme } = useTheme();
    const { isOpen, onClose: onClose, open } = useToggle();
    const setTheme: typeof _setTheme = (theme) => {
        _setTheme?.(theme);
        onClose();
    };
    return (
        <Popup
            isOpen={isOpen}
            close={onClose}
            content={
                <div className="absolute right-0 top-full translate-y-4 bg-white dark:bg-darkSurface-200 text-black dark:text-white dark:ring-0 dark:highlight-white/5 rounded-xl ring-1 ring-slate-900/5 shadow-lg overflow-hidden dark:border dark:border-darkSurface-600">
                    <button
                        className="w-full flex items-center gap-2 pl-2 pr-16 py-2 hover:bg-irGray-200 dark:hover:bg-darkSurface-300"
                        onClick={() => setTheme("light")}
                    >
                        <div>
                            <SunIcon
                                className={theme === "light" ? "stroke-sky-500" : "stroke-slate-500 dark:stroke-white"}
                            />
                        </div>
                        <div className={`capitalize ${theme === "light" ? "text-sky-500" : ""}`}>
                            {translateFunc("common:light")}
                        </div>
                    </button>
                    <button
                        className="w-full flex items-center gap-2 pl-2 pr-16 py-2 hover:bg-irGray-200 dark:hover:bg-darkSurface-300"
                        onClick={() => setTheme("dark")}
                    >
                        <div>
                            <MoonIcon
                                className={theme === "dark" ? "fill-sky-500" : "fill-slate-500 dark:fill-white"}
                            />
                        </div>
                        <div className={`capitalize ${theme === "dark" ? "text-sky-500" : ""}`}>
                            {translateFunc("common:dark")}
                        </div>
                    </button>
                    <button
                        className="w-full flex items-center gap-2 pl-2 pr-16 py-2 hover:bg-irGray-200 dark:hover:bg-darkSurface-300"
                        onClick={() => setTheme("system")}
                    >
                        <div>
                            <MonitorIcon
                                className={theme === "system" ? "stroke-sky-500" : "stroke-slate-900 dark:stroke-white"}
                            />
                        </div>
                        <div className={`capitalize ${theme === "system" ? "text-sky-500" : ""}`}>
                            {translateFunc("common:system")}
                        </div>
                    </button>
                </div>
            }
        >
            <button aria-label={translateFunc("common:open-theme-popup")} onClick={open}>
                {appliedTheme === "dark" && <MoonIcon className="fill-sky-500" />}
                {appliedTheme === "light" && <SunIcon className="stroke-sky-500" />}
            </button>
        </Popup>
    );
};
