export const TrophyIcon = () => {
    return (
        <svg
            width="30"
            height="30"
            viewBox="0 0 34 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-black dark:fill-white"
        >
            <path d="M27.6555 18.333C31.1292 18.1567 34 15.4334 34 12.2195V5.31818C34 4.86655 33.6094 4.5 33.1282 4.5H27.8974V0.818182C27.8974 0.366545 27.5069 0 27.0256 0H6.97436C6.49313 0 6.10256 0.366545 6.10256 0.818182V4.5H0.871795C0.390564 4.5 0 4.86655 0 5.31818V12.2195C0 15.433 2.87038 18.1567 6.34362 18.3334C7.33441 22.673 11.2919 25.9945 16.1282 26.3483V34.3636H9.58974C9.10851 34.3636 8.71795 34.7302 8.71795 35.1818C8.71795 35.6335 9.10851 36 9.58974 36H24.4103C24.8915 36 25.2821 35.6335 25.2821 35.1818C25.2821 34.7302 24.8915 34.3636 24.4103 34.3636H17.8718V26.3459C22.7089 25.9805 26.6638 22.664 27.6555 18.333ZM32.2564 6.13636V12.2195C32.2564 14.4352 30.2726 16.3955 27.8848 16.6762C27.8922 16.5203 27.8974 16.3636 27.8974 16.2061V6.13636H32.2564ZM1.74359 12.2195V6.13636H6.10256V16.2061C6.10256 16.3636 6.10779 16.5203 6.1152 16.6762C3.72736 16.3951 1.74359 14.4352 1.74359 12.2195ZM7.84615 16.2061V1.63636H26.1538V16.2061C26.1538 20.9152 22.0398 24.7463 16.983 24.7463C11.9449 24.7463 7.84615 20.9152 7.84615 16.2061Z" />
        </svg>
    );
};
