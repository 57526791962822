import { Width } from "client/types/Width";
import { Height } from "client/types/Height";

export interface IZipIconProps {
    width?: number | Width;
    height?: number | Height;
}

export const ZipIcon = ({ width, height }: IZipIconProps) => {
    const _width = width ?? 34;
    const _height = height ?? 30;

    return (
        <svg
            width={_width}
            height={_height}
            viewBox="0 0 44 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-black dark:fill-white"
        >
            <path d="M39.2804 6.5598H19.1943L17.666 2.73996C17.2239 1.63404 15.9913 0.799805 14.8004 0.799805H2.80039C1.47703 0.799805 0.400391 1.87644 0.400391 3.1998V31.0398C0.400391 33.4216 2.33815 35.3598 4.72039 35.3598H17.2973C17.7433 37.5481 19.6825 39.1998 22.0004 39.1998C24.3183 39.1998 26.2575 37.5481 26.7034 35.3598H39.2804C41.6626 35.3598 43.6004 33.4216 43.6004 31.0398V10.8798C43.6004 8.49804 41.6626 6.5598 39.2804 6.5598ZM2.80039 2.7198H14.8004C15.1988 2.7198 15.7354 3.08316 15.8837 3.45324L17.126 6.5598H4.72039C3.83287 6.5598 3.00727 6.82956 2.32039 7.29036V3.1998C2.32039 2.93964 2.54023 2.7198 2.80039 2.7198ZM17.2935 33.4398H4.72039C3.39703 33.4398 2.32039 32.3632 2.32039 31.0398V10.8798C2.32039 9.55645 3.39703 8.4798 4.72039 8.4798H21.0404V9.43981H19.1204V11.3598H21.0404V13.2798H19.1204V15.1998H21.0404V17.1198H19.1204V19.0398H21.0404V20.9598H19.1204V22.8798H21.0404V23.8398H19.1204C18.3265 23.8398 17.6804 24.4859 17.6804 25.2798V27.1998C17.6804 27.8512 18.1177 28.3964 18.7124 28.5736C18.2309 29.8864 17.5397 31.9278 17.2935 33.4398ZM24.4004 26.7198H19.6004V25.7598H24.4004V26.7198ZM22.0004 37.2798C20.4121 37.2798 19.1204 35.9881 19.1204 34.3998C19.1204 33.1604 20.1356 30.2257 20.737 28.6398H23.2637C23.8652 30.2257 24.8804 33.1604 24.8804 34.3998C24.8804 35.9881 23.5887 37.2798 22.0004 37.2798ZM41.6804 31.0398C41.6804 32.3632 40.6037 33.4398 39.2804 33.4398H26.7073C26.461 31.9278 25.7698 29.8864 25.2884 28.5736C25.8831 28.3964 26.3204 27.8512 26.3204 27.1998V25.2798C26.3204 24.4859 25.6743 23.8398 24.8804 23.8398H22.9604V20.9598H24.8804V19.0398H22.9604V17.1198H24.8804V15.1998H22.9604V13.2798H24.8804V11.3598H22.9604V8.4798H39.2804C40.6037 8.4798 41.6804 9.55645 41.6804 10.8798V31.0398ZM23.4404 34.3998C23.4404 35.1952 22.7962 35.8398 22.0004 35.8398C21.2045 35.8398 20.5604 35.1952 20.5604 34.3998C20.5604 33.6044 21.2045 32.9598 22.0004 32.9598C22.7962 32.9598 23.4404 33.6044 23.4404 34.3998Z" />
        </svg>
    );
};
