import { ReactNode } from "react";

interface ContentItemProps {
    title: string | ReactNode;
    description: string | ReactNode;
    icon: ReactNode;
}

export const ContentItem = ({ title, description, icon }: ContentItemProps) => {
    return (
        <div className="flex flex-col items-center items center gap-6 w-full text-black dark:text-white">
            <div>{icon}</div>
            <h2 className="font-semibold text-center">{title}</h2>
            <p className="text-center">{description}</p>
        </div>
    );
};
